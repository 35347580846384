import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import firebase from "gatsby-plugin-firebase"
import { Layout, Hero, About, Jobs, Featured, Projects, Contact } from '@components';
// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import ReactGA from 'react-ga';

// const firebaseConfig = {
//   apiKey: "AIzaSyDpprfF5VCBaw4jv9-0YTV96VcrRAZ1U0s",
//   authDomain: "emre-tokerler-website.firebaseapp.com",
//   projectId: "emre-tokerler-website",
//   storageBucket: "emre-tokerler-website.appspot.com",
//   messagingSenderId: "104576772456",
//   appId: "1:104576772456:web:fc05c1011d57829454cedd",
//   measurementId: "G-0Y0F5MSRE0"
// };

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics();

ReactGA.initialize('UA-44362059-1');
ReactGA.pageview("/");


const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <StyledMainContainer className="fillHeight">
      <Hero />
      <About />
      <Jobs />
      <Featured />
      <Projects />
      <Contact />
    </StyledMainContainer>



  </Layout>


);




IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
